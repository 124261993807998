import { Link } from "@remix-run/react";

export function RedirectLink({
  to,
  userLoggedIn,
  loginOrSignUp,
  className,
  children,
  id,
}: {
  to: string;
  userLoggedIn: boolean;
  loginOrSignUp: "login" | "sign-up";
  className: string;
  children: React.ReactNode;
  id?: string;
}) {
  return (
    <Link
      id={id}
      to={
        userLoggedIn
          ? to
          : `/${loginOrSignUp}?redirectTo=${encodeURIComponent(to)}`
      }
      className={className}
    >
      {children}
    </Link>
  );
}
